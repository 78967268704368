exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-header-js": () => import("./../../../src/pages/about-me/header.js" /* webpackChunkName: "component---src-pages-about-me-header-js" */),
  "component---src-pages-about-me-hotspots-js": () => import("./../../../src/pages/about-me/hotspots.js" /* webpackChunkName: "component---src-pages-about-me-hotspots-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-header-js": () => import("./../../../src/pages/contact/header.js" /* webpackChunkName: "component---src-pages-contact-header-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-message-js": () => import("./../../../src/pages/contact/message.js" /* webpackChunkName: "component---src-pages-contact-message-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-clases-header-js": () => import("./../../../src/pages/es/clases/header.js" /* webpackChunkName: "component---src-pages-es-clases-header-js" */),
  "component---src-pages-es-clases-how-works-groups-js": () => import("./../../../src/pages/es/clases/how-works-groups.js" /* webpackChunkName: "component---src-pages-es-clases-how-works-groups-js" */),
  "component---src-pages-es-clases-how-works-individuals-js": () => import("./../../../src/pages/es/clases/how-works-individuals.js" /* webpackChunkName: "component---src-pages-es-clases-how-works-individuals-js" */),
  "component---src-pages-es-clases-js": () => import("./../../../src/pages/es/clases.js" /* webpackChunkName: "component---src-pages-es-clases-js" */),
  "component---src-pages-es-clases-more-js": () => import("./../../../src/pages/es/clases/more.js" /* webpackChunkName: "component---src-pages-es-clases-more-js" */),
  "component---src-pages-es-conoceme-header-js": () => import("./../../../src/pages/es/conoceme/header.js" /* webpackChunkName: "component---src-pages-es-conoceme-header-js" */),
  "component---src-pages-es-conoceme-hotspots-js": () => import("./../../../src/pages/es/conoceme/hotspots.js" /* webpackChunkName: "component---src-pages-es-conoceme-hotspots-js" */),
  "component---src-pages-es-conoceme-js": () => import("./../../../src/pages/es/conoceme.js" /* webpackChunkName: "component---src-pages-es-conoceme-js" */),
  "component---src-pages-es-contacto-header-js": () => import("./../../../src/pages/es/contacto/header.js" /* webpackChunkName: "component---src-pages-es-contacto-header-js" */),
  "component---src-pages-es-contacto-js": () => import("./../../../src/pages/es/contacto.js" /* webpackChunkName: "component---src-pages-es-contacto-js" */),
  "component---src-pages-es-contacto-message-js": () => import("./../../../src/pages/es/contacto/message.js" /* webpackChunkName: "component---src-pages-es-contacto-message-js" */),
  "component---src-pages-es-faqs-header-js": () => import("./../../../src/pages/es/faqs/header.js" /* webpackChunkName: "component---src-pages-es-faqs-header-js" */),
  "component---src-pages-es-faqs-js": () => import("./../../../src/pages/es/faqs.js" /* webpackChunkName: "component---src-pages-es-faqs-js" */),
  "component---src-pages-es-faqs-questions-js": () => import("./../../../src/pages/es/faqs/questions.js" /* webpackChunkName: "component---src-pages-es-faqs-questions-js" */),
  "component---src-pages-es-home-advantages-js": () => import("./../../../src/pages/es/home/advantages.js" /* webpackChunkName: "component---src-pages-es-home-advantages-js" */),
  "component---src-pages-es-home-comments-js": () => import("./../../../src/pages/es/home/comments.js" /* webpackChunkName: "component---src-pages-es-home-comments-js" */),
  "component---src-pages-es-home-free-trial-js": () => import("./../../../src/pages/es/home/free-trial.js" /* webpackChunkName: "component---src-pages-es-home-free-trial-js" */),
  "component---src-pages-es-home-group-lessons-js": () => import("./../../../src/pages/es/home/group-lessons.js" /* webpackChunkName: "component---src-pages-es-home-group-lessons-js" */),
  "component---src-pages-es-home-header-js": () => import("./../../../src/pages/es/home/header.js" /* webpackChunkName: "component---src-pages-es-home-header-js" */),
  "component---src-pages-es-home-how-works-js": () => import("./../../../src/pages/es/home/how-works.js" /* webpackChunkName: "component---src-pages-es-home-how-works-js" */),
  "component---src-pages-es-home-individual-lessons-js": () => import("./../../../src/pages/es/home/individual-lessons.js" /* webpackChunkName: "component---src-pages-es-home-individual-lessons-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-opiniones-header-js": () => import("./../../../src/pages/es/opiniones/header.js" /* webpackChunkName: "component---src-pages-es-opiniones-header-js" */),
  "component---src-pages-es-opiniones-js": () => import("./../../../src/pages/es/opiniones.js" /* webpackChunkName: "component---src-pages-es-opiniones-js" */),
  "component---src-pages-es-politica-privacidad-header-js": () => import("./../../../src/pages/es/politica-privacidad/header.js" /* webpackChunkName: "component---src-pages-es-politica-privacidad-header-js" */),
  "component---src-pages-es-politica-privacidad-js": () => import("./../../../src/pages/es/politica-privacidad.js" /* webpackChunkName: "component---src-pages-es-politica-privacidad-js" */),
  "component---src-pages-es-politica-privacidad-privacy-js": () => import("./../../../src/pages/es/politica-privacidad/privacy.js" /* webpackChunkName: "component---src-pages-es-politica-privacidad-privacy-js" */),
  "component---src-pages-es-success-message-js": () => import("./../../../src/pages/es/success-message.js" /* webpackChunkName: "component---src-pages-es-success-message-js" */),
  "component---src-pages-es-success-payment-js": () => import("./../../../src/pages/es/success-payment.js" /* webpackChunkName: "component---src-pages-es-success-payment-js" */),
  "component---src-pages-faqs-header-js": () => import("./../../../src/pages/faqs/header.js" /* webpackChunkName: "component---src-pages-faqs-header-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-faqs-questions-js": () => import("./../../../src/pages/faqs/questions.js" /* webpackChunkName: "component---src-pages-faqs-questions-js" */),
  "component---src-pages-home-advantages-js": () => import("./../../../src/pages/home/advantages.js" /* webpackChunkName: "component---src-pages-home-advantages-js" */),
  "component---src-pages-home-comments-js": () => import("./../../../src/pages/home/comments.js" /* webpackChunkName: "component---src-pages-home-comments-js" */),
  "component---src-pages-home-free-trial-js": () => import("./../../../src/pages/home/free-trial.js" /* webpackChunkName: "component---src-pages-home-free-trial-js" */),
  "component---src-pages-home-group-lessons-js": () => import("./../../../src/pages/home/group-lessons.js" /* webpackChunkName: "component---src-pages-home-group-lessons-js" */),
  "component---src-pages-home-header-js": () => import("./../../../src/pages/home/header.js" /* webpackChunkName: "component---src-pages-home-header-js" */),
  "component---src-pages-home-how-works-js": () => import("./../../../src/pages/home/how-works.js" /* webpackChunkName: "component---src-pages-home-how-works-js" */),
  "component---src-pages-home-individual-lessons-js": () => import("./../../../src/pages/home/individual-lessons.js" /* webpackChunkName: "component---src-pages-home-individual-lessons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-header-js": () => import("./../../../src/pages/lessons/header.js" /* webpackChunkName: "component---src-pages-lessons-header-js" */),
  "component---src-pages-lessons-how-works-groups-js": () => import("./../../../src/pages/lessons/how-works-groups.js" /* webpackChunkName: "component---src-pages-lessons-how-works-groups-js" */),
  "component---src-pages-lessons-how-works-individuals-js": () => import("./../../../src/pages/lessons/how-works-individuals.js" /* webpackChunkName: "component---src-pages-lessons-how-works-individuals-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-lessons-more-js": () => import("./../../../src/pages/lessons/more.js" /* webpackChunkName: "component---src-pages-lessons-more-js" */),
  "component---src-pages-privacy-policy-header-js": () => import("./../../../src/pages/privacy-policy/header.js" /* webpackChunkName: "component---src-pages-privacy-policy-header-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-privacy-js": () => import("./../../../src/pages/privacy-policy/privacy.js" /* webpackChunkName: "component---src-pages-privacy-policy-privacy-js" */),
  "component---src-pages-reviews-header-js": () => import("./../../../src/pages/reviews/header.js" /* webpackChunkName: "component---src-pages-reviews-header-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-reviews-opinions-js": () => import("./../../../src/pages/reviews/opinions.js" /* webpackChunkName: "component---src-pages-reviews-opinions-js" */),
  "component---src-pages-success-message-js": () => import("./../../../src/pages/success-message.js" /* webpackChunkName: "component---src-pages-success-message-js" */),
  "component---src-pages-success-payment-js": () => import("./../../../src/pages/success-payment.js" /* webpackChunkName: "component---src-pages-success-payment-js" */)
}

