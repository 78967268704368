import 'bootstrap/dist/css/bootstrap.min.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './src/styles/main.scss';


export const onInitialClientRender = () => {
  AOS.init({
    duration: 600,
    disable: window.innerWidth < 800,
  });
};